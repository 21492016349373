import React from "react";
import { useForm } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { apiUpdateClient } from "store/api";
import FormCheck from "shared/FormCheck";
import { clientAtom } from "store/client";
import { useRecoilState } from "recoil";

const SubscriptionTab = ({ client, updateClient }) => {
  const hAlert = useAlert();

  const [clientState, setClientState] = useRecoilState(clientAtom);

  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      status: client.status,
      enable_chat: client.enable_chat,
      is_free_trial: client.is_free_trial,
      is_sunroof_enabled: client.is_sunroof_enabled,
      is_phone_verification_required: client.is_phone_verification_required,
      enable_automated_text_follow_up: client.enable_automated_text_follow_up,
      is_instant_estimate_api_enabled: client.is_instant_estimate_api_enabled,
      enable_sonic: client.enable_sonic,
    },
  });

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (d) => {
    setLoading(true);
    const {
      status,
      enable_chat,
      is_free_trial,
      is_sunroof_enabled,
      is_phone_verification_required,
      enable_automated_text_follow_up,
      is_instant_estimate_api_enabled,
      enable_sonic,
    } = d;

    const formData = new FormData();
    formData.append("status", status);
    // formData.append("status", status);
    formData.append("enable_chat", enable_chat);
    formData.append("is_free_trial", is_free_trial);
    formData.append("is_sunroof_enabled", is_sunroof_enabled);
    formData.append("is_phone_verification_required", is_phone_verification_required);
    formData.append("enable_automated_text_follow_up", enable_automated_text_follow_up);
    formData.append("is_instant_estimate_api_enabled", is_instant_estimate_api_enabled);
    formData.append("enable_sonic", enable_sonic);

    const res = await apiUpdateClient(client.id)(formData);
    if (res.status === 200) {
      setClientState({
        ...clientState,
        all: [...clientState.all.filter((d) => d.id !== client.id), res.data],
        current: clientState.currentId === client.id ? res.data : clientState.current,
      });

      updateClient(res.data);
      hAlert.show("Client saved successfully!");
    }
    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack p={4} spacing={4}>
        <Box></Box>

        <FormCheck disabled={loading} control={control} name="status" label="Active" />
        <FormCheck disabled={loading} control={control} name="enable_chat" label="Enable Chat" />
        <FormCheck disabled={loading} control={control} name="is_free_trial" label="Temporarily Pause Billing" />
        <FormCheck disabled={loading} control={control} name="is_sunroof_enabled" label="Enable Sunroof" />
        <FormCheck
          disabled={loading}
          control={control}
          name="is_phone_verification_required"
          label="Require Phone Number Verification"
        />
        <FormCheck
          disabled={loading}
          control={control}
          name="enable_automated_text_follow_up"
          label="Enable Automated Text Follow-Ups"
        />
        <FormCheck
          disabled={loading}
          control={control}
          name="is_instant_estimate_api_enabled"
          label="Enable Instant Estimate API &amp; Reporting"
        />
        {/* <FormCheck disabled={loading} control={control} name="enable_sonic" label="Enable Sonic" /> */}

        <LoadingButton loading={loading} variant="contained" type="submit">
          SAVE CLIENT
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default SubscriptionTab;
