import React from "react";
import { Box } from "@mui/material";
import Switch from "shared/Switch";
import useConfigurator from "hooks/useConfigurator";

const EnableBanner = () => {
  const hConfigurator = useConfigurator();
  const [value, setValue] = React.useState(false);

  React.useEffect(() => {
    setValue(hConfigurator.client.banner_is_enabled);
    // eslint-disable-next-line
  }, []);

  const handleChange = async (e) => {
    setValue(e.target.checked);
    await hConfigurator.saveSingle("banner_is_enabled", e.target.checked);
  };

  return (
    <Box>
      <Switch label="Enable Banner" checked={value} onChange={handleChange} />
    </Box>
  );
};

export default EnableBanner;
