import React from "react";
import moment from "moment";
import { useRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Box,
  Paper,
  Button,
  CircularProgress,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
} from "@mui/material";
import useAlert from "hooks/useAlert";
import { apiGetUsers, apiSendInvite, apiDeleteUsers } from "store/api";
import { clientAtom } from "store/client";
import { SendIcon } from "utils/icons";
import { getDisplayName } from "utils/generators";
import TableAction from "./TableAction";

const TeamListPage = () => {
  const navigate = useNavigate();
  const [clientState] = useRecoilState(clientAtom);
  const hAlert = useAlert();
  const [loading, setLoading] = React.useState(true);
  const mounted = React.useRef(true);
  const [users, setUsers] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    const getUsers = async () => {
      const res = await apiGetUsers({
        solar_company: clientState.currentId,
      });
      if (res.status === 200 && mounted.current) {
        setUsers(res.data);
        setLoading(false);
      }
    };
    getUsers();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSelected([]);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((d) => d.id));
    } else {
      setSelected([]);
    }
  };

  const handleSelectSingle = (event, user) => {
    event.stopPropagation();
    if (selected.includes(user.id)) {
      setSelected(selected.filter((d) => d !== user.id));
    } else {
      setSelected([...selected, user.id]);
    }
  };

  const handleRemove = async () => {
    setLoading(true);
    const res = await apiDeleteUsers(
      {
        solar_company: clientState.currentId,
      },
      {
        user_ids: selected,
      }
    );
    if (res.status === 200) {
      setUsers(users.filter((d) => !res.data.deleted_user_ids.includes(d.id)));
    }
    hAlert.show(`${res.data.deleted_user_ids.length} users removed successfully!`);
    if (mounted.current) {
      setLoading(false);
      setSelected([]);
    }
  };

  const handleInvite = async (user) => {
    const { email, first_name, last_name, role } = user;
    const res = await apiSendInvite({
      email,
      first_name,
      last_name,
      role,
      solar_company: clientState.currentId,
    });
    if (res.status === 200) {
      hAlert.show("Invitation sent");
    }
  };

  const showUserDetail = (user) => {
    if (user.status !== "pending") {
      navigate(`/settings/team/${user.id}`);
    }
  };

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 24, color: "primary.main", flexGrow: 1 }}>Team</Box>
        <Button component={Link} variant="contained" to="/settings/team/new">
          Invite New User
        </Button>
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          overflow: "hidden",
        }}
      >
        {loading ? (
          <Box pt={4} sx={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box sx={{ height: 1 }}>
            <Box sx={{ height: "calc(100% - 50px)" }}>
              <Scrollbars>
                <Table stickyHeader sx={{ "& th": { whiteSpace: "nowrap" } }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selected.length === rowsPerPage || selected.length === users.length}
                          indeterminate={
                            selected.length > 0 && selected.length < rowsPerPage && selected.length < users.length
                          }
                          onClick={(e) => handleSelectAll(e)}
                        />
                      </TableCell>
                      <TableCell>{selected.length > 0 ? <TableAction onRemove={handleRemove} /> : "Name"}</TableCell>
                      <TableCell>Client</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Date Joined</TableCell>
                      <TableCell>Last Logged In</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((d) => (
                      <TableRow
                        key={d.id}
                        sx={{ "&:hover": { backgroundColor: "#eee", cursor: "pointer" } }}
                        onClick={() => showUserDetail(d)}
                      >
                        <TableCell>
                          <Checkbox checked={selected.includes(d.id)} onClick={(e) => handleSelectSingle(e, d)} />
                        </TableCell>
                        <TableCell>{getDisplayName(d)}</TableCell>
                        <TableCell>{clientState.companyName}</TableCell>
                        <TableCell>{d.email}</TableCell>
                        <TableCell>{d.phone}</TableCell>
                        <TableCell>{moment(d.date_joined).format("MMMM D, YYYY")}</TableCell>
                        <TableCell>{d.last_login && moment(d.last_login).format("MMMM D, YYYY")}</TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>{d.status}</TableCell>
                        <TableCell>
                          {d.status === "pending" && (
                            <IconButton onClick={() => handleInvite(d)}>
                              <SendIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbars>
            </Box>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                height: 50,
                borderTop: "1px solid #ccc",
                "& .MuiToolbar-root": { minHeight: 0 },
              }}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default TeamListPage;
