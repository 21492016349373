import { useRecoilState } from "recoil";
import { clientAtom } from "store/client";
import { apiUpdateClient } from "store/api";
import { configuratorAtom } from "store/configurator";
import useAlert from "./useAlert";

let counter = 0; // helper variable to catch latest response

const useConfigurator = () => {
  const hAlert = useAlert();
  const [clientState, setClientState] = useRecoilState(clientAtom);
  const [confState, setConfState] = useRecoilState(configuratorAtom);

  const init = async () => {
    setConfState({
      ...confState,
      client: clientState.current,
    });
  };

  const saveSingle = async (key, value) => {
    counter++;
    const myCounter = counter;
    setConfState({
      ...confState,
      saving: true,
      updates: {
        ...confState.updates,
        [key]: value,
      },
    });

    const formData = new FormData();
    formData.append(key, value);
    const res = await apiUpdateClient(confState.client.id)(formData);

    if (myCounter === counter) {
      setConfState({
        ...confState,
        saving: false,
        // client: res.status === 200 ? res.data : confState.client,
      });

      if (res.status === 200) {
        setClientState({
          ...clientState,
          current: res.data,
        });
      }
    }

    if (res.status === 200) {
      hAlert.show("Client Information Saved successfully!");
    } else {
      hAlert.show(res.data.detail || "Something went wrong", false);
    }

    return res;
  };

  const saveMany = async (values) => {
    counter++;
    const myCounter = counter;

    setConfState({
      ...confState,
      saving: true,
    });

    const formData = new FormData();

    for (let i = 0; i < values.length; i++) {
      formData.append(values[i].key, values[i].value);
    }

    const res = await apiUpdateClient(confState.client.id)(formData);

    if (myCounter === counter) {
      setConfState({
        ...confState,
        saving: false,
        client: res.status === 200 ? res.data : confState.client,
      });
    }

    if (res.status === 200) {
      hAlert.show("Client Information Saved successfully!");
    } else {
      hAlert.show(res.data.detail || "Something went wrong", false);
    }

    return res;
  };

  const saveAll = async () => {
    counter++;
    const myCounter = counter;

    setConfState({
      ...confState,
      saving: true,
    });

    const formData = new FormData();
    for (const key in confState.updates) {
      formData.append(key, confState.updates[key]);
    }

    const res = await apiUpdateClient(confState.client.id)(formData);

    if (myCounter === counter) {
      setConfState({
        ...confState,
        client: res.status === 200 ? res.data : confState.client,
      });
    }
  };

  return {
    ...confState,
    customDomainValidated: clientState.customDomainValidated,
    init,
    saveSingle,
    saveMany,
    saveAll,
  };
};

export default useConfigurator;
