import moment, { now } from "moment";
import { format, startOfDay, endOfDay, addMonths, subDays, startOfMonth, endOfMonth } from "date-fns";
import { isPrime } from "utils/math";

const DUMMY_START_DATE = "2021-01-01";

export const getDummyValue = ({ startAt, endAt, isInstant, isTotal }) => {
  let start = moment.utc(startAt).unix();
  let end = Math.min(moment.utc(endAt).unix(), moment.utc().unix());

  if (isTotal) start = moment(DUMMY_START_DATE).unix();
  else start = Math.max(start, moment(DUMMY_START_DATE).unix());

  const factor = isInstant ? 10000 : 950;
  const from = parseInt(start / factor),
    to = parseInt(end / factor);

  const data = {
    visitor: 0,
    contant: 0,
    lead: 0,
    disqualified_lead: 0,
    qualified_lead: 0,
    appointment: 0,
    appointment_with_bill: 0,
  };

  const ratio = {
    visitor: 90,
    contant: 30,
    lead: 18,
    disqualified_lead: 1,
    qualified_lead: 10,
    appointment: 5,
    appointment_with_bill: 2,
  };

  for (let i = from; i <= to; i++) {
    if (!isPrime(i)) continue;
    Object.keys(ratio).forEach((key) => {
      data[key] += 1 + (i % ratio[key]);
    });
  }

  return data;
};

export const calcDashBoardDummy = (data) => {
  data.forEach((d, i) => {
    const lead_status = d.lead_status;

    d.data.forEach((d, j) => {
      const dummy = getDummyValue({
        startAt: format(startOfDay(new Date(d.year, d.month - 1, d.day)), "yyyy-MM-dd'T'HH:mm:ssxxxxx"),
        endAt: format(endOfDay(new Date(d.year, d.month - 1, d.day)), "yyyy-MM-dd'T'HH:mm:ssxxxxx"),
      })[lead_status];
      data[i].data[j].count += dummy;
    });

    const A =
      getDummyValue({
        startAt: format(startOfDay(subDays(new Date(), 28)), "yyyy-MM-dd'T'HH:mm:ssxxxxx"),
        endAt: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ssxxxxx"),
      })[lead_status] + data[i].count;

    const B = getDummyValue({
      startAt: format(startOfDay(subDays(new Date(), 56)), "yyyy-MM-dd'T'HH:mm:ssxxxxx"),
      endAt: format(endOfDay(subDays(new Date(), 29)), "yyyy-MM-dd'T'HH:mm:ssxxxxx"),
    })[lead_status];

    data[i].count = A;
    data[i].percent = B === 0 ? 0 : parseInt(((A - B) / B) * 100);
  });

  return data;
};

export const calcGraphDummy = (data) => {
  let start = new Date(DUMMY_START_DATE);

  while (start <= now()) {
    const dummy = getDummyValue({
      startAt: format(startOfMonth(start), "yyyy-MM-dd'T'HH:mm:ssxxxxx"),
      endAt: format(endOfMonth(start), "yyyy-MM-dd'T'HH:mm:ssxxxxx"),
    });
    const index = data.data.findIndex((d) => d.year === start.getFullYear() && d.month === start.getMonth() + 1);
    if (index < 0)
      data.data.push({ year: start.getFullYear(), month: start.getMonth() + 1, count: dummy[data.lead_status] });
    else data.data[index].count += dummy[data.lead_status];
    start = addMonths(start, 1);
  }

  data.count = data.data.reduce((tot, cur) => tot + cur.count, 0);

  return data;
};
