import React from "react";
import { Box, TextField } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const ChangeProductTerm = () => {
  const hConfigurator = useConfigurator();
  const [productTerm, setProductTerm] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setProductTerm(hConfigurator.client.product_term || "");
    // eslint-disable-next-line
  }, []);

  const handleTermChange = (e) => {
    setProductTerm(e.target.value);
    if (e.target.value === "") {
      setError("required");
    } else {
      if (parseFloat(e.target.value) >= 0) {
        setError("");
      }
    }
  };

  const handleTermUpdate = () => {
    if (productTerm !== "" && parseFloat(productTerm) > 0) {
      hConfigurator.saveSingle("product_term", productTerm);
    }
  };

  return (
    <Box>
      <Box my={2}>Product Term</Box>
      {error && (
        <Box sx={{ color: "error.main" }}>
          {error === "required" && "Required"}
          {error !== "required" && error}
        </Box>
      )}
      <TextField type="number" value={productTerm} onChange={handleTermChange} onBlur={handleTermUpdate} />
    </Box>
  );
};

export default ChangeProductTerm;
