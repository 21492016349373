import { Stack } from "@mui/material";
import WizardCheck from "../shared/WizardCheck";

const SubscriptionForm = () => {
  return (
    <Stack>
      <WizardCheck name="status" label="Active" />
      <WizardCheck name="is_free_trial" label="Temporarily Pause Billing" />
      <WizardCheck name="is_sunroof_enabled" label="Enable Sunroof" />
      <WizardCheck name="is_phone_verification_required" label="Require Phone Number Verification" />
      <WizardCheck name="enable_automated_text_follow_up" label="Enable Automated Text Follow-Ups" />
      <WizardCheck name="is_instant_estimate_api_enabled" label="Enable Instant Estimate API &amp; Reporting" />
    </Stack>
  );
};

export default SubscriptionForm;
