import React from "react";
import { useRecoilState } from "recoil";
import { Button, CircularProgress, Box } from "@mui/material";
import { Line } from "react-chartjs-2";
import moment from "moment-timezone";
import { clientAtom } from "store/client";
import { apiGetLeadStatusByPeriod } from "store/api";
import { calcGraphDummy as calcDummy } from "utils/dummy";

const chartLabels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
const chartOptions = (max) => ({
  spanGaps: false,
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 32,
      left: 32,
      right: 32,
    },
  },
  elements: {
    point: {
      radius: 4,
      borderWidth: 2,
      hoverRadius: 4,
      hoverBorderWidth: 2,
    },
    line: {
      tension: 0,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
          tickMarkLength: 18,
        },
        ticks: {
          fontColor: "#ffffff",
        },
      },
    ],
    yAxes: [
      {
        display: false,
        ticks: {
          min: 0,
          max,
          stepSize: 1,
        },
      },
    ],
  },
  plugins: {
    xLabelsOnTop: {
      active: true,
    },
  },
});

const getDataset = (values) => {
  let dataset = [
    {
      data: values ? [...values] : [],
      label: "Visitor",
      fill: "start",
      borderColor: "#fff",
      backgroundColor: "#fff",
      pointBackgroundColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointBorderColor: "#15ABE6",
      pointHoverBorderColor: "#15ABE6",
    },
  ];
  if (values.length < 12) {
    const missingValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    missingValues.fill(null, 0, values.length);
    dataset.push({
      data: [...missingValues],
      label: "Visitor",
      fill: "start",
      borderColor: "#fff",
      backgroundColor: "#fff",
      pointBackgroundColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointBorderColor: "#15ABE6",
      pointHoverBorderColor: "#15ABE6",
    });
  }
  return dataset;
};

const VisitorGraph = () => {
  const [clientState] = useRecoilState(clientAtom);
  const [state, setState] = React.useState({
    loading: true,
    allYears: [],
    currentYear: new Date().getFullYear(),
    dataHash: {},
    max: 0,
  });
  const chartRef = React.useRef(null);

  React.useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      setState({
        ...state,
        loading: true,
      });

      const res = await apiGetLeadStatusByPeriod({
        period_length: 24,
        period: "months",
        solar_company: clientState.currentId,
        timezone: moment.tz.guess(),
      });

      if (res.status === 200 && isMounted) {
        const visitors =
          clientState.companyName === "Demand IQ"
            ? calcDummy(res.data.find((d) => d.lead_status === "visitor"))
            : res.data.find((d) => d.lead_status === "visitor");
        if (visitors.data[0]) {
          console.log(visitors);
          let minYear = visitors.data[0].year;
          let max = visitors.data[0].count;
          const dataHash = {
            [minYear]: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          };

          for (let i = 0; i < visitors.data.length; i++) {
            const { year, month, count } = visitors.data[i];

            if (!dataHash[year]) {
              dataHash[year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            }
            dataHash[year][month - 1] = count;

            if (minYear > year) {
              minYear = year;
            }

            if (max < count) {
              max = count;
            }
          }

          const currentYear = new Date().getFullYear();

          for (let i = minYear; i <= currentYear; i++) {
            if (!dataHash[i]) {
              dataHash[i] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            }
          }

          const currentMonth = new Date().getMonth();
          for (let i = currentMonth; i < 12; i++) {
            dataHash[currentYear].pop();
          }

          setState({
            ...state,
            loading: false,
            allYears: Object.keys(dataHash),
            currentYear: currentYear,
            dataHash,
            max,
          });
        } else {
          setState({
            ...state,
            loading: false,
          });
        }
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
    //eslint-disable-next-line
  }, [clientState.currentId]);

  const handleYearSelect = (year) => {
    if (year !== state.currentYear) {
      setState({
        ...state,
        loading: true,
        currentYear: year,
      });

      setTimeout(() => {
        setState({
          ...state,
          loading: false,
          currentYear: year,
        });
      }, 500);
    }
  };

  return (
    <Box sx={{ backgroundColor: "primary.main", color: "#fff" }}>
      <Box p={2} sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Box sx={{ fontSize: 20 }}>Visitors</Box>
          <Box sx={{ fontSize: 14, color: "#fffc" }}>Unique visitors by month</Box>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: "flex" }}>
          {state.allYears.map((year) => (
            <Button
              key={year}
              size="small"
              sx={{ color: "#fff", ":disabled": { color: "#ddd" } }}
              onClick={() => handleYearSelect(year)}
              disabled={parseInt(year) === parseInt(state.currentYear)}
            >
              {year}
            </Button>
          ))}
        </Box>
      </Box>

      <Box pb={2} sx={{ height: 256 }}>
        {state.loading ? (
          <Box sx={{ width: 1, height: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Line
            ref={chartRef}
            data={{
              labels: chartLabels,
              datasets: getDataset(state.dataHash[state.currentYear] || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
            }}
            options={chartOptions(state.max + Math.max(10, state.max / 5))}
          />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(VisitorGraph);
