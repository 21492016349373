import React from "react";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Div100vh from "react-div-100vh";
import { Box, Stack, Button, Paper } from "@mui/material";
import FormText from "shared/FormText";
import "./style.scss";
import { apiCheckInviteKey, apiAcceptInvite } from "store/api";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";

const InvitationPage = () => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, setValue } = useForm();
  const hAlert = useAlert();
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkInvitationKey = async () => {
      setLoading(true);

      const { data } = await apiCheckInviteKey(key);

      const { email, first_name, last_name, status, message } = data;

      if (status === "accepted") {
        hAlert.show(message, false);
        navigate("/login");
      }

      if (status === "expired") {
        hAlert.show(message, false);
        navigate("/login");
      }

      if (status === "joined") {
        hAlert.show(message, true);
        navigate("/login");
      }

      if (message === "An invalid invitation key was submitted.") {
        hAlert.show(message, false);
        navigate("/login");
      }

      setValue("email", email);
      setValue("first_name", first_name);
      setValue("last_name", last_name);

      setLoading(false);
    };

    checkInvitationKey();
  }, [key, setValue]);

  const onSubmit = async (d) => {
    setLoading(true);

    const { first_name, last_name, email, password } = d;

    const formData = new FormData();
    formData.append("invitation_key", key);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("password", password);

    const res = await apiAcceptInvite(formData);

    if (res.status === 201) {
      navigate("/login");
    }

    setLoading(false);
  };

  if (!key) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <Div100vh className="invitation-page">
      <Box className="section-left">
        <Stack className="promotion" spacing={4}>
          <Box sx={{ fontSize: 24, fontWeight: 900, textTransform: "uppercase" }}>Enterprise</Box>
          <Box sx={{ fontSize: 28 }}>The most advanced customer acquisition platform for clean energy.</Box>
          <Box>
            <Button color="inherit" variant="contained" sx={{ backgroundColor: "#fff", color: "primary.main" }}>
              Learn more
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box className="section-main">
        <Box className="section-main-container">
          <Paper sx={{ width: 480 }}>
            <Box p={4}>
              <Box sx={{ textAlign: "center" }}>
                <img className="stella-logo" src="/images/stella.png" alt="" />
              </Box>
              <Box mt={2} mb={4} sx={{ fontSize: 24, textAlign: "center" }}>
                Invitation to join Demand IQ
              </Box>
              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2}>
                    <FormText
                      control={control}
                      disabled={loading}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="first_name"
                      label="First Name *"
                    />
                    <FormText
                      control={control}
                      disabled={loading}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="last_name"
                      label="Last Name *"
                    />
                    <FormText
                      control={control}
                      disabled={loading}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="email"
                      label="Email Address *"
                    />
                    <FormText control={control} disabled={loading} type="password" name="password" label="Password *" />
                    <LoadingButton loading={loading} fullWidth type="submit" variant="contained">
                      Create Account
                    </LoadingButton>
                  </Stack>
                </form>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Div100vh>
  );
};

export default InvitationPage;
