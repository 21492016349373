import React from "react";
import { Routes, Route } from "react-router-dom";

import AuthLayer from "shared/permission/AuthLayer";
import SuperLayer from "shared/permission/SuperLayer";
import AdminLayer from "shared/permission/AdminLayer";
import UserLayer from "shared/permission/UserLayer";
import RepLayer from "shared/permission/RepLayer";

import LoginPage from "./login/LoginPage";
import ForgotPasswordPage from "./forgot-password/ForgotPasswordPage";
import ResetPasswordPage from "./reset-password/ResetPasswordPage";
import InvitationPage from "./invitation/InvitationPage";
import InstantEstimatePage from "./products/instant-estimate/InstantEstimatePage";

import DashboardPage from "./dashboard/DashboardPage";
import ConfiguratorPage from "./products/configurator/ConfiguratorPage";
import ExperimentsPage from "./products/experiments/ExperimentsPage";
import ReportingPage from "./products/reporting/ReportingPage";

import PaymentPage from "./billing/payment/PaymentPage";
import EarnRewardsPage from "./billing/earn-rewards/EarnRewardsPage";

import ClientListPage from "./settings/client/client-list-page/ClientListPage";
import ClientAddPage from "./settings/client/client-add-page/ClientAddPage";
import ClientEditPage from "./settings/client/client-edit-page/ClientEditPage";
import TeamListPage from "./settings/team/team-list-page/TeamListPage";
import TeamInvitePage from "./settings/team/team-invite-page/TeamInvitePage";
import TeamUserPage from "./settings/team/team-user-page/TeamUserPage";
import ProfilePage from "./settings/profile/ProfilePage";

import NoMatch from "./NoMatch";
import { useRecoilState } from "recoil";
import { clientAtom } from "store/client";

function PageRoute() {
  const [clientState] = useRecoilState(clientAtom);
  const isInstantEstimateApiEnabled = clientState.current?.is_instant_estimate_api_enabled;

  return (
    <Routes>
      <Route
        path="login"
        element={
          <AuthLayer>
            <LoginPage />
          </AuthLayer>
        }
      />
      <Route
        path="forgot-password"
        element={
          <AuthLayer>
            <ForgotPasswordPage />
          </AuthLayer>
        }
      />
      <Route
        path="password/reset/confirm/:uid/:token"
        element={
          <AuthLayer>
            <ResetPasswordPage />
          </AuthLayer>
        }
      />
      <Route
        path="invitation"
        element={
          <AuthLayer>
            <InvitationPage />
          </AuthLayer>
        }
      />
      <Route
        path="dashboard"
        element={
          <UserLayer>
            <DashboardPage />
          </UserLayer>
        }
      />

      <Route
        path="products/configurator"
        element={
          <UserLayer>
            <ConfiguratorPage />
          </UserLayer>
        }
      />
      <Route
        path="products/experiments"
        element={
          <SuperLayer>
            <ExperimentsPage />
          </SuperLayer>
        }
      />
      <Route
        path="products/reporting"
        element={
          <UserLayer>
            <ReportingPage />
          </UserLayer>
        }
      />

      {isInstantEstimateApiEnabled && (
        <Route
          path="products/instant-estimate"
          element={
            <RepLayer>
              <InstantEstimatePage />
            </RepLayer>
          }
        />
      )}

      <Route
        path="billing/payments"
        element={
          <UserLayer>
            <PaymentPage />
          </UserLayer>
        }
      />
      <Route
        path="billing/earn-rewards"
        element={
          <UserLayer>
            <EarnRewardsPage />
          </UserLayer>
        }
      />

      <Route
        path="settings/clients"
        element={
          <SuperLayer>
            <ClientListPage />
          </SuperLayer>
        }
      />
      <Route
        path="settings/clients/new"
        element={
          <SuperLayer>
            <ClientAddPage />
          </SuperLayer>
        }
      />
      <Route
        path="settings/clients/:id"
        element={
          <SuperLayer>
            <ClientEditPage />
          </SuperLayer>
        }
      />
      <Route
        path="settings/team"
        element={
          <AdminLayer>
            <TeamListPage />
          </AdminLayer>
        }
      />
      <Route
        path="settings/team/new"
        element={
          <AdminLayer>
            <TeamInvitePage />
          </AdminLayer>
        }
      />
      <Route
        path="settings/team/:id"
        element={
          <AdminLayer>
            <TeamUserPage />
          </AdminLayer>
        }
      />
      <Route
        path="settings/profile"
        element={
          <RepLayer>
            <ProfilePage />
          </RepLayer>
        }
      />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default PageRoute;
