import React from "react";
import { Button, Box } from "@mui/material";
import { CheckIcon } from "utils/icons";
import useConfigurator from "hooks/useConfigurator";

const requiredFields = [
  "energy_type",
  "button_shape",
  "billing_email",
  "advertise_phone",
  "advertise_email",
  "website_url",
  "testimonials_url",
  "privacy_policy",
  "reviews_url",
  "offset",
  "ai_name",
];

const checkCompleted = (client) => {
  for (const field of requiredFields) {
    if (!client[field]) {
      return false;
    }
  }
  return true;
};

const Welcome = ({ showWizard }) => {
  const hConfigurator = useConfigurator();

  return (
    <>
      {checkCompleted(hConfigurator.client) ? (
        <Box p={4}>
          <Box sx={{ display: "flex", alignItems: "center", color: "success.main" }}>
            <CheckIcon fontSize={20} />
            <Box ml={0.5}>Setup Completed</Box>
          </Box>
          <Box pt={8} sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Box mb={2}>
                <img width="160" src="/images/cocktail.svg" alt="logo" />
              </Box>
              <Box sx={{ fontSize: 24 }}>Your version of Stella is fully configured!</Box>
              <Box sx={{ fontSize: 14 }}>Please choose from the available options below.</Box>
              <Button
                variant="contained"
                color="primary"
                component="a"
                href={hConfigurator.client.app_url}
                target="_blank"
                sx={{ marginTop: 4, width: 200 }}
              >
                View Live Preview
              </Button>
              <Button variant="outlined" color="primary" onClick={showWizard} sx={{ marginTop: 2, width: 200 }}>
                Edit Funnel
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box pt={8} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              maxWidth: 384,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box mb={2}>
              <img src="/images/gift.svg" alt="gift" />
            </Box>
            <Box sx={{ fontSize: 34 }}>Welcome to Stella From Demand IQ!</Box>
            <Box sx={{ fontSize: 14 }}>Click below to set up your version of Stella</Box>
            <Box mt={4}>
              <Button variant="contained" color="primary" size="large" onClick={showWizard}>
                Configure Stella
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Welcome;
