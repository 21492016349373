import React from "react";
import { TextField } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const SingleText = ({ field, label }) => {
  const hConfigurator = useConfigurator();
  const [touched, setTouched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    setValue(hConfigurator.client[field]);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setTouched(true);
    setValue(e.target.value);
  };

  const handleBlur = async () => {
    if (touched) {
      setLoading(true);
      await hConfigurator.saveSingle(field, value);
      setTouched(false);
      setLoading(false);
    }
  };

  return (
    <TextField
      fullWidth
      label={label}
      disabled={loading}
      sx={{ maxWidth: 400 }}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default SingleText;
