import React from "react";
import Iframe from "react-iframe";
import { Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import useSpanishSetupWizard from "hooks/useSpanishSetupWizard";
import { assign, cloneDeep } from "lodash-es";

const DesktopPreview = ({ page = "", isQuickSetup = false, isSpanishSetup = false }) => {
  const quickSetupWizard = useQuickSetupWizard();
  const spanishSetupWizard = useSpanishSetupWizard();

  const [client, setClient] = React.useState(null);
  const [iFrameSrc, setIFrameSrc] = React.useState("");

  const getDesktopPreviewIFrameSrc = (companySlug, lang = null) => {
    if (!companySlug) return "";

    const env = process.env.REACT_APP_TRACKING_ENV;
    const pageParam = page ? `&page=${page}` : "";
    const langParam = lang ? `&lang=${lang}` : "";

    if (env === "production") {
      return `https://stella.demand-iq.com?app_url=https://${companySlug}.estimate.demand-iq.com&preview=true${pageParam}${langParam}`;
    } else if (env === "staging") {
      return `https://stella-staging.demand-iq.com?app_url=https://${companySlug}.estimate-staging.demand-iq.com&preview=true${pageParam}${langParam}`;
    } else {
      return `https://stella-dev.demand-iq.com?app_url=https://${companySlug}.calculator-dev.demand-iq.com&preview=true${pageParam}${langParam}`;
    }
  };

  React.useEffect(() => {
    if (isQuickSetup) {
      setClient(quickSetupWizard.client);
      setIFrameSrc(getDesktopPreviewIFrameSrc(quickSetupWizard.client.company_slug));
    }

    if (isSpanishSetup) {
      setClient(spanishSetupWizard.client);
      setIFrameSrc(getDesktopPreviewIFrameSrc(spanishSetupWizard.client.company_slug, "es"));
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (quickSetupWizard.updates.length <= 0) return;

    let updates = {};
    for (let i = 0; i < quickSetupWizard.updates.length; i++) {
      updates = { ...updates, [quickSetupWizard.updates[i].key]: quickSetupWizard.updates[i].value };
    }

    const theme = assign(cloneDeep(client), updates);
    const previewIframe = document.getElementById("desktopPreviewIframe");
    previewIframe.contentWindow.postMessage(theme, previewIframe.src);
  }, [quickSetupWizard.updates]);

  React.useEffect(() => {
    if (spanishSetupWizard.updates.length <= 0) return;

    let updates = {};
    for (let i = 0; i < spanishSetupWizard.updates.length; i++) {
      updates = { ...updates, [spanishSetupWizard.updates[i].key]: spanishSetupWizard.updates[i].value };
    }

    const theme = assign(cloneDeep(client), updates);
    const previewIframe = document.getElementById("desktopPreviewIframe");
    previewIframe.contentWindow.postMessage(theme, previewIframe.src);
  }, [spanishSetupWizard.updates]);

  return (
    <Box
      sx={{
        backgroundSize: "960px 460px",
        backgroundRepeat: "no-repeat",
        height: 650,
        borderRadius: 1,
        padding: 1,
        border: "1px solid #000040",
        color: client ? client.color_scheme_font : "#000",
        "& iframe": {
          border: "none",
        },
      }}
    >
      <Iframe src={iFrameSrc} height="100%" width="100%" id="desktopPreviewIframe" style={{ border: "none" }} />
    </Box>
  );
};

export default DesktopPreview;
