import React from "react";
import { useRecoilState } from "recoil";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Dialog,
  Box,
  CircularProgress,
  Stack,
  TextField,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import useReporting from "hooks/useReporting";
import { apiGetExportLeads, apiGetClientQuestions } from "store/api";
import { authAtom } from "store/auth";
import { CloseIcon } from "utils/icons";
import DateTemplateMenu from "../../shared/DateTemplateMenu";
import { tableColumns, leadStatusLabels } from "../../shared/const";
import LeadStatusTableCheck from "./LeadStatusTableCheck";
import LeadStatusTableSelect from "./LeadStatusTableSelect";
import { isSuper } from "utils/authHelper";
import { OPEN_ENDED_QN } from "utils/constant";

const generateCell = (data, key) => {
  if (key === "first_session") {
    return moment(data[key]).format("M/D/YYYY h:m");
  }
  if (key === "last_session") {
    return moment(data[key]).format("M/D/YYYY h:m");
  }
  if (key === "lead_status") {
    return leadStatusLabels[data[key]];
  }
  return data[key];
};

const generateAnswerCell = (data, qn) => {
  const answers = data.answers.filter((answer) => answer.question.id === qn.id);
  if (answers && answers.length) {
    return answers[0].open_ended_answer;
  }
  return null;
};

const LeadStatusTable = ({ tableData, closeTable }) => {
  const hReporting = useReporting();
  const [authState] = useRecoilState(authAtom);
  const [loading, setLoading] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [data, setData] = React.useState([]);
  const [savedData, setSavedData] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [openEndedQns, setOpenEndedQns] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    const init = async (lang = "en") => {
      const res = await apiGetClientQuestions(hReporting.client.id, lang);
      if (mounted && res.status === 200) {
        setOpenEndedQns(res.data.filter((question) => question.question_type === OPEN_ENDED_QN));
      }
    };
    init();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (tableData.open && changed) {
      updateData(startDate, endDate);
      setChanged(false);
    } else {
      setData(savedData);
      setStartDate(hReporting.conversion.filter.startDate);
      setEndDate(hReporting.conversion.filter.endDate);
    }
    // eslint-disable-next-line
  }, [tableData.open]);

  React.useEffect(() => {
    setStatusFilter([tableData.status]);
    setData(savedData);
    setStartDate(hReporting.conversion.filter.startDate);
    setEndDate(hReporting.conversion.filter.endDate);
    // eslint-disable-next-line
  }, [tableData.status]);

  React.useEffect(() => {
    setChanged(true);
    setStartDate(hReporting.conversion.filter.startDate);
    setEndDate(hReporting.conversion.filter.endDate);
    // eslint-disable-next-line
  }, [hReporting.conversion.filter.startDate, hReporting.conversion.filter.endDate]);

  const updateData = async (start, end) => {
    const res = await getData(start, end);
    if (res.status === 200) {
      setSavedData(res.data);
    }
  };

  const getData = async (start, end) => {
    setLoading(true);

    const res = await apiGetExportLeads({
      format: "json",
      min_last_session: hReporting.formatStartDate(start),
      max_first_session: hReporting.formatEndDate(end),
      timezone: moment.tz.guess(),
      lead_status: ["visitor", "lead", "disqualified_lead", "qualified_lead", "appointment", "appointment_with_bill"],
      solar_company: hReporting.client.id,
    });

    if (res.status === 200) {
      setData(res.data);
    }
    setLoading(false);
    return res;
  };

  const handleTemplateSelect = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    getData(start, end);
  };

  const handleStartDateUpdate = (date) => {
    setStartDate(date);
    getData(date, endDate);
  };

  const handleEndDateUpdate = (date) => {
    setEndDate(date);
    getData(startDate, date);
  };

  const handleStatusFilterChange = (filter) => {
    setStatusFilter(filter);
  };

  return (
    <Dialog maxWidth="lg" open={tableData.open} onClose={closeTable} PaperProps={{ sx: { width: "100%" } }}>
      <Box p={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <DateTemplateMenu disabled={loading} onSelect={handleTemplateSelect} />
            <DatePicker
              disabled={loading}
              value={startDate}
              maxDate={endDate}
              onChange={handleStartDateUpdate}
              renderInput={(params) => <TextField {...params} />}
            />
            <Box sx={{ width: 10, height: "1px", backgroundColor: "#000" }} />
            <DatePicker
              disabled={loading}
              value={endDate}
              minDate={startDate}
              onChange={handleEndDateUpdate}
              renderInput={(params) => <TextField {...params} />}
            />
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={closeTable}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </LocalizationProvider>
      </Box>
      <Divider />
      <LeadStatusTableCheck defaultChecked={tableData.status} onChange={handleStatusFilterChange} />
      <Divider />
      <Box sx={{ height: 600 }}>
        {loading ? (
          <Box mt={4} sx={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box sx={{ width: 1, height: 1 }}>
            {data.filter((d) => statusFilter.includes(d.lead_status)).length === 0 ? (
              <Box
                sx={{
                  width: 1,
                  height: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 24,
                }}
              >
                No Result
              </Box>
            ) : (
              <Scrollbars>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      {tableColumns.map((d) => (
                        <TableCell key={d.header} sx={{ whiteSpace: "nowrap" }}>
                          {d.header}
                        </TableCell>
                      ))}
                      {openEndedQns.map((d) => (
                        <TableCell key={d.question_key} sx={{ whiteSpace: "nowrap" }}>
                          {d.question_label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .filter((d) => statusFilter.includes(d.lead_status))
                      .map((d, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>{i + 1}</TableCell>
                          {tableColumns.map((c) => (
                            <TableCell key={c.header} sx={{ whiteSpace: "nowrap" }}>
                              {isSuper(authState.user) && c.key === "lead_status" ? (
                                <LeadStatusTableSelect status={d[c.key]} />
                              ) : (
                                generateCell(d, c.key)
                              )}
                            </TableCell>
                          ))}
                          {openEndedQns.map((c) => (
                            <TableCell key={c.question_key} sx={{ whiteSpace: "nowrap" }}>
                              {generateAnswerCell(d, c)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Scrollbars>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default LeadStatusTable;
