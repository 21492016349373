import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { Button, Menu, Box, OutlinedInput, MenuItem, ListItemText } from "@mui/material";
import { ArrowDownIcon, SearchIcon, PremiumIcon } from "utils/icons";
import { clientAtom } from "store/client";
import { apiGetClient, apiValidateClientDns } from "store/api";
import { configuratorAtom, quickSetupAtom, spanishSetupAtom } from "store/configurator";
import { customMapsAtom } from "store/customMaps";
import { experimentAtom } from "store/experiment";
import { reportingClientAtom } from "store/reporting";

const CompanyMenu = () => {
  const navigate = useNavigate();

  const [clientState, setClientState] = useRecoilState(clientAtom);
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [autoFocus, setAutoFocus] = React.useState(false);

  const open = Boolean(anchorEl);

  const resetConfiguratorAtom = useResetRecoilState(configuratorAtom);
  const resetQuickSetupAtom = useResetRecoilState(quickSetupAtom);
  const resetSpanishSetupAtom = useResetRecoilState(spanishSetupAtom);
  const resetCustomMapsAtom = useResetRecoilState(customMapsAtom);
  const resetExperimentAtom = useResetRecoilState(experimentAtom);
  const resetReportingClientAtom = useResetRecoilState(reportingClientAtom);

  const resetAtoms = () => {
    resetConfiguratorAtom();
    resetQuickSetupAtom();
    resetSpanishSetupAtom();
    resetCustomMapsAtom();
    resetExperimentAtom();
    resetReportingClientAtom();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAutoFocus(true);
    setSearchText("");
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      const filteredClients = clientState.all.filter((d) =>
        d.company_name.toLowerCase().toString().includes(searchText.toLowerCase())
      );

      if (filteredClients.length > 0) {
        handleClientSelect(filteredClients[0].id, filteredClients[0].company_name);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchUpdate = (e) => {
    setSearchText(e.target.value);
  };

  const handleClientSelect = async (clientId, companyName) => {
    resetAtoms();
    setClientState({
      ...clientState,
      current: null,
      currentId: clientId,
      companyName,
    });

    Promise.all([apiGetClient(clientId), apiValidateClientDns(clientId)()]).then(([clientRes, dnsRes]) => {
      const { data: client } = clientRes;
      const { data: dns } = dnsRes;

      setClientState((clientState) => {
        if (client.id !== clientState.currentId) return clientState;
        return {
          ...clientState,
          current: client,
          customDomainValidated: dns.custom_domain?.valid,
        };
      });
    });

    localStorage.setItem("company", clientId);

    handleClose();
    navigate("/dashboard");
  };

  return (
    <Box px={4} my={2} sx={{ display: "flex", alignItems: "flex-start" }}>
      <img src="/images/company.svg" alt="" />

      <Button
        color="inherit"
        endIcon={<ArrowDownIcon />}
        sx={{ marginLeft: 1, alignItems: "flex-start", lineHeight: "20px", textAlign: "left" }}
        onClick={handleClick}
        disableFocusRipple
      >
        {clientState.companyName}
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ disablePadding: true }}>
        <Box sx={{ width: 165, maxHeight: "60vh" }}>
          <Box p={1}>
            <OutlinedInput
              size="small"
              placeholder="Search ..."
              value={searchText}
              onChange={handleSearchUpdate}
              endAdornment={<SearchIcon fontSize={24} />}
              sx={{ fontSize: 14 }}
              autoFocus={autoFocus}
              onKeyUp={handleKeyUp}
            />
          </Box>

          {clientState.all
            .filter((d) => d.status)
            .filter((d) => d.company_name.toLowerCase().toString().includes(searchText.toLowerCase()))
            .map((d) => (
              <MenuItem
                key={d.id}
                sx={{ borderTop: "1px solid #eee" }}
                onClick={() => handleClientSelect(d.id, d.company_name)}
              >
                <ListItemText>
                  <Box sx={{ fontSize: 14, wordBreak: "break-word", whiteSpace: "pre-wrap", width: 108 }}>
                    {d.company_name}
                  </Box>
                </ListItemText>

                {d.is_free_trial && (
                  <Box sx={{ color: "primary.main", display: "flex" }}>
                    <PremiumIcon />
                  </Box>
                )}
              </MenuItem>
            ))}
        </Box>
      </Menu>
    </Box>
  );
};

export default CompanyMenu;
