import React from "react";
import { Box, MenuItem, Select } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import Switch from "shared/wizard/Switch";
import { GAP_TIME_LIST, TIMEZONE_LIST } from "utils/constant";

const AppointmentAvailability = () => {
  const hWizard = useQuickSetupWizard();
  const [duplication, setDuplication] = React.useState(false);
  const [gapTime, setGapTime] = React.useState("");
  const [timezone, setTimezone] = React.useState("");

  React.useEffect(() => {
    setDuplication(hWizard.client.is_duplication_meeting_prevented);
    setGapTime(hWizard.client.appointment_gap_time);
    setTimezone(hWizard.client.appointment_timezone);
    // eslint-disable-next-line
  }, []);

  const handleSwitchDuplication = (e) => {
    setDuplication(e.target.checked);
    hWizard.update("is_duplication_meeting_prevented", e.target.checked);
  };

  const handleGapTimeChange = (e) => {
    setGapTime(e.target.value);
    hWizard.update("appointment_gap_time", e.target.value);
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
    hWizard.update("appointment_timezone", e.target.value);
  };

  return (
    <Box>
      <Box pl={1} my={2}>
        <Switch
          label="Prevent multiple meetings from being scheduled at the same time?"
          checked={duplication}
          onChange={handleSwitchDuplication}
        />
      </Box>
      <Box my={2}>
        <Box sx={{ mb: 2, fontSize: 12 }}>Select your buffer time between appointments.</Box>
        <Select value={gapTime} onChange={handleGapTimeChange} sx={{ width: 280 }}>
          {GAP_TIME_LIST.map((gapTime, i) => (
            <MenuItem key={i} value={gapTime.value}>
              {gapTime.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box my={2}>
        <Box sx={{ mb: 2, fontSize: 12 }}>
          Please select your hours of availability and the appropriate time zone for the schedule.
        </Box>
        <Select value={timezone} onChange={handleTimezoneChange} sx={{ width: 280 }}>
          {TIMEZONE_LIST.map((tz, i) => (
            <MenuItem key={i} value={tz.value}>
              {tz.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default AppointmentAvailability;
