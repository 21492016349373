import React from "react";
import { Box, Stack, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import DesktopPreview from "shared/Preview/DesktopPreview";

const ChangeThanks = () => {
  const setupWizard = useQuickSetupWizard();
  const [thanks, setThanks] = React.useState({
    thanks_caption: "",
    thanks_statement: "",
  });
  const [errors, setErrors] = React.useState({
    thanks_caption: "",
    thanks_statement: "",
  });

  React.useEffect(() => {
    setThanks({
      thanks_caption: setupWizard.client.thanks_caption || "Your Quote is Being Prepared!",
      thanks_statement: setupWizard.client.thanks_statement || "",
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setErrors({
      thanks_caption: setupWizard.errors.thanks_caption,
      thanks_statement: setupWizard.errors.thanks_statement,
    });
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleChange = (e, key, len) => {
    if (e.target.value.length <= len) {
      if (e.target.value === "") {
        setErrors((state) => ({
          ...state,
          [key]: "required",
        }));
      } else {
        setErrors((state) => ({
          ...state,
          [key]: "",
        }));
      }
      setThanks((state) => ({ ...state, [key]: e.target.value }));
      setupWizard.update(key, e.target.value);
    }
  };

  return (
    <Box>
      <Stack mt={2} spacing={2}>
        {[
          { key: "thanks_caption", len: 32 },
          { key: "thanks_statement", len: 255 },
        ].map(({ key, len }) => (
          <>
            {errors[key] && (
              <Box sx={{ color: "error.main" }}>
                {errors[key] === "required" && "Required"}
                {errors[key] !== "required" && errors[key]}
              </Box>
            )}
            <TextField
              fullWidth
              value={thanks[key]}
              onChange={(e) => handleChange(e, key, len)}
              label={`${thanks[key].length}/${len}`}
            />
          </>
        ))}
      </Stack>

      <Box mt={4}>
        <DesktopPreview page="thankyou" isQuickSetup />
      </Box>
    </Box>
  );
};

export default ChangeThanks;
