import React from "react";
import { useRecoilValue } from "recoil";
import { Scrollbars } from "react-custom-scrollbars";
import { Box, Paper, Tabs, Tab } from "@mui/material";
import { clientAtom } from "store/client";
import useReporting from "hooks/useReporting";
import PageLoading from "shared/PageLoading";
import MainReport from "./components/main-report/MainReport";
import LeadConversion from "./components/lead-conversion/LeadConversion";
import SonicReport from "./components/sonic-report/SonicReport";
import Stella2Report from "./components/stella2-report/Stella2Report";

const ReportingPage = () => {
  const clientState = useRecoilValue(clientAtom);
  const hReporting = useReporting();
  const [tab, setTab] = React.useState(0);

  const isInstantEstimateApiEnabled = clientState.current?.is_instant_estimate_api_enabled;
  // const isStella2Enabled = clientState.current?.enable_chat;
  const isStella2Enabled = false;
  const isSonicEnabled = clientState.current?.enable_sonic;

  React.useEffect(() => {
    hReporting.init();
    // eslint-disable-next-line
  }, [clientState.current]);

  const handleChange = (e, v) => {
    setTab(v);
  };

  if (!hReporting.client) {
    return <PageLoading />;
  }

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 24, color: "primary.main", flexGrow: 1 }}>Reporting</Box>
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box px={2} sx={{ borderBottom: 1, borderColor: "divider", height: 50 }}>
          <Tabs value={tab} onChange={handleChange} sx={{ height: 50 }}>
            <Tab label="Lead Capture" />

            {isInstantEstimateApiEnabled && <Tab label="Instant Estimate API" />}
            {isStella2Enabled && <Tab label="Stella 2" />}
            {isSonicEnabled && <Tab label="Project Sonic" />}
          </Tabs>
        </Box>
        <Box sx={{ height: "calc(100% - 50px)" }}>
          <Scrollbars>
            {tab === 0 && <MainReport />}

            {tab === 1 && isInstantEstimateApiEnabled && <LeadConversion />}
            {tab === 1 + isInstantEstimateApiEnabled && isStella2Enabled && <Stella2Report />}
            {tab === 1 + isInstantEstimateApiEnabled + isStella2Enabled && isSonicEnabled && <SonicReport />}
          </Scrollbars>
        </Box>
      </Paper>
    </Box>
  );
};

export default ReportingPage;
