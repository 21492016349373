import { Box, Stack, Tooltip, IconButton } from "@mui/material";
import { QuestionCircleIcon } from "utils/icons";
import "shared/tooltip.scss";
import SingleTextArea from "../shared/SingleTextArea";

const validator = (script) => {
  if (!script) return true;

  const parser = new DOMParser();
  const doc = parser.parseFromString(script, "application/xml");
  const errorNode = doc.querySelector("parsererror");

  if (errorNode) return false;
  return true;
};

const CustomCodes = () => {
  const helperText = "That script does not seem well-formed.";

  return (
    <Box p={4}>
      <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
          CustomCodes for Status of Lead
        </Box>

        <Tooltip
          classes={{
            tooltip: "tooltip-background",
            arrow: "tooltip-caret",
          }}
          title={
            <Box sx={{ fontSize: 14, fontWeight: 400 }}>
              <Box>To learn more about this please visit</Box>
              <a
                className="tooltip-link"
                href="https://knowledge.demand-iq.com/google-ads-server-to-server-conversion-tracking"
                target="_blank"
                rel="noopener noreferrer"
              >
                our knowledge base article
              </a>
            </Box>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <QuestionCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Stack spacing={4}>
        <SingleTextArea
          field="custom_code_visitor"
          label="Custom Code - Visitor"
          validator={validator}
          helperText={helperText}
        />
        <SingleTextArea
          field="custom_code_lead"
          label="Custom Code - Lead"
          validator={validator}
          helperText={helperText}
        />
        <SingleTextArea
          field="custom_code_qualified_lead"
          label="Custom Code - Qualified Lead"
          validator={validator}
          helperText={helperText}
        />
        <SingleTextArea
          field="custom_code_disqualified_lead"
          label="Custom Code - Disqualified Lead"
          validator={validator}
          helperText={helperText}
        />
        <SingleTextArea
          field="custom_code_appointment"
          label="Custom Code - Appointment"
          validator={validator}
          helperText={helperText}
        />
        <SingleTextArea
          field="custom_code_utility_bill"
          label="Custom Code - Utility Bill"
          validator={validator}
          helperText={helperText}
        />
      </Stack>
    </Box>
  );
};

export default CustomCodes;
