import React from "react";
import { useRecoilState } from "recoil";
import { Box, Button, Dialog, Stack, ButtonBase } from "@mui/material";
import useCommon from "hooks/useCommon";
import { apiSetPaymentMethod, apiDetachPaymentMethod } from "store/api";
import { clientAtom } from "store/client";
import { CreditCardIcon } from "utils/icons";
import CreatePayment from "./CreatePayment";
import StripeWrapper from "./StripeWrapper";

const ManagePayments = ({ payments, onUpdateDefault, onCreate, onDelete }) => {
  const hCommon = useCommon();
  const [clientState] = useRecoilState(clientAtom);
  const [form, setForm] = React.useState("");
  const mounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleSetDefault = async (payment) => {
    hCommon.loading("Setting Default Payment ...");
    const res = await apiSetPaymentMethod({
      customer: clientState.current.customer_id,
      payment_method_id: payment.id,
      type: "setDefault",
    });
    if (res.status === 200 && mounted.current) {
      onUpdateDefault();
      setForm("");
    }
    hCommon.loading("");
  };

  const handleDetachPayment = async (payment) => {
    hCommon.loading("Deleting Payment ...");
    const res = await apiDetachPaymentMethod({
      payment_method_id: payment.id,
      type: "detach",
    });
    if (res.status === 200 && mounted.current) {
      onDelete();
      setForm("");
    }
    hCommon.loading("");
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setForm("payment-list")}>
        MANAGE
      </Button>
      <Dialog
        open={form === "payment-list"}
        onClose={() => setForm("")}
        maxWidth="xs"
        PaperProps={{ sx: { width: "100%" } }}
      >
        <Box p={4}>
          <Box mb={4} sx={{ fontSize: 20, fontWeight: 500 }}>
            Payment Methods
          </Box>
          <Stack spacing={2}>
            {payments.map((d) => (
              <Box key={d.id} p={2} sx={{ border: "1px solid #2196f3", display: "flex", alignItems: "center" }}>
                <Box
                  mr={4}
                  component="img"
                  src={`/images/card-brand-logos/light-color/${d.card.brand}.png`}
                  alt={d.card.brand}
                  sx={{ height: 88 }}
                />
                <Box sx={{ fontSize: 14 }}>
                  <Box mb={0.5}>{`${d.card.brand} ****${d.card.last4}`}</Box>
                  <Box mb={0.5}>{`Expires ${d.card.exp_month} / ${d.card.exp_year}`}</Box>
                  <Box sx={{ display: "flex" }}>
                    <Button onClick={() => handleSetDefault(d)} sx={{ marginRight: 1 }}>
                      Primary
                    </Button>
                    <Button onClick={() => handleDetachPayment(d)}>Delete</Button>
                  </Box>
                </Box>
              </Box>
            ))}
            <ButtonBase
              sx={{ border: "1px solid #2196f3", color: "primary.main", height: 100 }}
              onClick={() => setForm("payment-create")}
            >
              <CreditCardIcon size={20} />
              <Box ml={1} sx={{ fontSize: 14 }}>
                NEW PAYMENT METHOD
              </Box>
            </ButtonBase>
          </Stack>
        </Box>
      </Dialog>
      <Dialog
        open={form === "payment-create"}
        onClose={() => setForm("")}
        maxWidth="xs"
        PaperProps={{ sx: { width: "100%" } }}
      >
        <Box p={4}>
          <StripeWrapper>
            <CreatePayment onCreate={onCreate} closeDialog={() => setForm("")} />
          </StripeWrapper>
        </Box>
      </Dialog>
    </>
  );
};

export default ManagePayments;
