import React from "react";
import { Box } from "@mui/material";
import ColorPicker from "../shared/ColorPicker";
import useConfigurator from "hooks/useConfigurator";

const keys = [
  "banner_bg_color",
  "banner_text_color",
  "banner_button_bg_color",
  "banner_button_text_color",
  "banner_button_hover_bg_color",
  "banner_button_hover_text_color",
];

const keyLabels = {
  banner_bg_color: "Background color",
  banner_text_color: "Font color",
  banner_button_bg_color: "Button color",
  banner_button_text_color: "Button label color",
  banner_button_hover_bg_color: "Button hover color",
  banner_button_hover_text_color: "Button hover label color",
};

const ColorScheme = () => {
  const hConfigurator = useConfigurator();

  const [values, setValues] = React.useState({
    banner_bg_color: "#fff",
    banner_text_color: "#fff",
    banner_button_bg_color: "#fff",
    banner_button_text_color: "#fff",
    banner_button_hover_bg_color: "#fff",
    banner_button_hover_text_color: "#fff",
  });

  React.useEffect(() => {
    setValues({
      banner_bg_color: hConfigurator.client.banner_bg_color,
      banner_text_color: hConfigurator.client.banner_text_color,
      banner_button_bg_color: hConfigurator.client.banner_button_bg_color,
      banner_button_text_color: hConfigurator.client.banner_button_text_color,
      banner_button_hover_bg_color: hConfigurator.client.banner_button_hover_bg_color,
      banner_button_hover_text_color: hConfigurator.client.banner_button_hover_text_color,
    });
    // eslint-disable-next-line
  }, []);

  const handleColorChange = (key) => (color) => {
    setValues({
      ...values,
      [key]: color,
    });
    hConfigurator.saveSingle(key, color);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", gap: 2, maxWidth: 600 }}>
      {keys.map((key) => (
        <Box key={key}>
          <Box>{keyLabels[key]}</Box>

          <ColorPicker value={values[key]} onChange={handleColorChange(key)} width={160} />
        </Box>
      ))}
    </Box>
  );
};

export default ColorScheme;
