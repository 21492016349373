import React from "react";
import { useRecoilState } from "recoil";
import Div100vh from "react-div-100vh";
import useAlert from "hooks/useAlert";
import { authAtom } from "store/auth";
import { apiGetToken } from "store/api";
import "./style.scss";
import { Box, Collapse, useMediaQuery } from "@mui/material";
import SideBar from "./components/SideBar";
import Main from "./components/Main";

const LoginPage = () => {
  const hAlert = useAlert();
  const [authState, setAuthState] = useRecoilState(authAtom);
  const [showPromotion, setShowPromotion] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const isDesktop = useMediaQuery("(min-width:900px)");

  const handleClosePromotion = () => {
    setShowPromotion(false);
  };

  const handleOpenPromotion = () => {
    setShowPromotion(true);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await apiGetToken(data);
    if (res.status === 200) {
      window.localStorage.setItem("jwt_access_token", res.data.access);
      window.localStorage.setItem("jwt_refresh_token", res.data.refresh);
      window.localStorage.setItem("jwt_nolt", res.data.noltToken);
      setAuthState({
        ...authState,
        user: res.data.user,
      });
    } else {
      setLoading(false);
      hAlert.show(res.data.detail, false);
    }
  };

  return (
    <Div100vh className="login-page">
      <Collapse
        orientation={isDesktop ? "horizontal" : "vertical"}
        in={showPromotion}
        timeout={100}
        sx={{ minWidth: "unset !important", minHeight: "fit-content !important" }}
      >
        <SideBar onClose={handleClosePromotion} />
      </Collapse>

      <Box sx={{ width: 1, height: 1 }}>
        <Main
          loading={loading}
          showPromotion={!showPromotion}
          onOpenPromotion={handleOpenPromotion}
          onSubmit={onSubmit}
        />
      </Box>
    </Div100vh>
  );
};

export default LoginPage;
