import React from "react";
import moment from "moment";
import { useRecoilState } from "recoil";
import { Box, Tooltip } from "@mui/material";
import { authAtom } from "store/auth";
import { getDisplayName } from "utils/generators";

const Avatar = ({ onChange, disabled = false }) => {
  const [authState] = useRecoilState(authAtom);
  const [preview, setPreview] = React.useState(null);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setPreview(reader.result);
        onChange(file);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    } else {
      setPreview(null);
      onChange(null);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Tooltip
        title={
          <>
            Upload Photo
            <br />
            min: 150px x 150px
          </>
        }
        arrow
        placement="bottom"
        sx={{
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <Box
          component="label"
          sx={{
            display: "flex",
            width: 80,
            height: 80,
            alignItems: "center",
            justifyContent: "center",
            background: "#ccc",
            borderRadius: "50%",
            overflow: "hidden",
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          <Box
            disabled={disabled}
            component="input"
            accept="image/*"
            type="file"
            onChange={handleChange}
            sx={{ display: "none" }}
          />
          {preview && <Box component="img" src={preview} sx={{ width: 1, height: 1, objectFit: "cover" }} />}
          {!preview && authState.user.avatar && (
            <Box component="img" src={authState.user.avatar} sx={{ width: 1, height: 1, objectFit: "cover" }} />
          )}
          {!preview && !authState.user.avatar && (
            <Box sx={{ color: "#fff", fontSize: 36 }}>{getDisplayName(authState.user, true)[0]}</Box>
          )}
        </Box>
      </Tooltip>
      <Box ml={2}>
        <Box mt={1} sx={{ fontSize: 20 }}>
          {getDisplayName(authState.user, true)}
        </Box>
        <Box mt={1} sx={{ fontSize: 14, color: "#888" }}>
          Joined: {moment(authState.user.date_joined).format("d MMM Y")}
        </Box>
      </Box>
    </Box>
  );
};

export default Avatar;
