import React from "react";
import { Box, Stack } from "@mui/material";
import EnableBanner from "./components/EnableBanner";
import ColorScheme from "./components/ColorScheme";
import Position from "./components/Position";
import HeadlineText from "./components/HeadlineText";
import ButtonText from "./components/ButtonText";
import EmbedScript from "./components/EmbedScript";
import UrlParams from "./components/UrlParams";
import BannerPreview from "shared/Preview/BannerPreview";
import BannerButtonShape from "./components/BannerButtonShape";

const Banner = () => {
  return (
    <Box p={4} sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Stack spacing={4}>
          <Box fontSize={20}>Banner Widget</Box>

          <EnableBanner />
          <ColorScheme />
          <Position />
          <BannerButtonShape />
          <HeadlineText />
          <ButtonText />
          <EmbedScript />
          <UrlParams />
        </Stack>
      </Box>

      <Box sx={{ width: 434, alignSelf: { xs: "center", md: "auto" } }}>
        <BannerPreview />
      </Box>
    </Box>
  );
};

export default Banner;
