import React from "react";
import { Box, Divider, rgbToHex, Stack, TextField, Typography, useTheme } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";
import EmbedScript from "../../shared/EmbedScript";
import { generateEmbedChatWidgetScript } from "utils/generators";
import RadioInput from "../banner-config/shared/RadioInput";
import AntSwitch from "./components/AntSwitch";
import ColorPicker from "shared/wizard/ColorPicker";
import UploadAiAvatar from "./components/UploadAiAvatar";
import UploadStarterHero from "./components/UploadStarterHero";

const EmbedChatWidget = () => {
  const hConfigurator = useConfigurator();

  const theme = useTheme();

  const positionOptions = [
    { label: "Left", value: "left" },
    { label: "Right", value: "right" },
  ];

  const [values, setValues] = React.useState({
    primary_color: hConfigurator.client.chat_widget_primary_color,
    accent_color: hConfigurator.client.chat_widget_accent_color,

    starter_display: hConfigurator.client.chat_widget_starter_display,
    starter_delay: hConfigurator.client.chat_widget_starter_delay,
    starter_text: hConfigurator.client.chat_widget_starter_text,

    ai_name: hConfigurator.client.chat_widget_ai_name,
    ai_subtitle: hConfigurator.client.chat_widget_ai_subtitle,

    position: "right",
  });

  let widgetScript = generateEmbedChatWidgetScript(hConfigurator.client.app_url, values.position);

  const handlePrimaryColorChange = (color) => {
    setValues({
      ...values,
      primary_color: rgbToHex(color),
      accent_color: rgbToHex(theme.palette.getContrastText(color)),
    });

    hConfigurator.saveMany([
      { key: "chat_widget_primary_color", value: rgbToHex(color) },
      { key: "chat_widget_accent_color", value: rgbToHex(theme.palette.getContrastText(color)) },
    ]);
  };

  const handleAccentColorChange = (e, value) => {
    setValues({
      ...values,
      accent_color: value ? "#000000de" : "#fff",
    });
    hConfigurator.saveSingle("chat_widget_accent_color", value ? "#000000de" : "#fff");
  };

  const handleStarterDisplayChange = (e, value) => {
    setValues({
      ...values,
      starter_display: value,
    });
    hConfigurator.saveSingle("chat_widget_starter_display", value);
  };

  const handleStarterDelayChange = (e) => {
    setValues({
      ...values,
      starter_delay: e.target.value,
    });
    hConfigurator.saveSingle("chat_widget_starter_delay", e.target.value);
  };

  const handleStarterTextChange = (e) => {
    if (e.target.value && e.target.value.length <= 255) {
      setValues({
        ...values,
        starter_text: e.target.value,
      });
      hConfigurator.saveSingle("chat_widget_starter_text", e.target.value);
    }
  };

  const handleAiNameChange = (e) => {
    setValues({
      ...values,
      ai_name: e.target.value,
    });
    hConfigurator.saveSingle("chat_widget_ai_name", e.target.value);
  };

  const handleAiSubtitleChange = (e) => {
    setValues({
      ...values,
      ai_subtitle: e.target.value,
    });
    hConfigurator.saveSingle("chat_widget_ai_subtitle", e.target.value);
  };

  const handlePositionChange = (e, value) => {
    setValues({
      ...values,
      position: value,
    });

    widgetScript = generateEmbedChatWidgetScript(hConfigurator.client.app_url, value);
  };

  return (
    <Stack>
      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Color</Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Primary color</Box>
              <ColorPicker value={values.primary_color} onChange={handlePrimaryColorChange} width={160} />
            </Stack>

            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Accent color</Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  sx={{
                    width: 58,
                    height: 30,
                    backgroundColor: "#fff",
                    borderRadius: "3px",
                    border: "1px rgb(73, 169, 222) solid",
                  }}
                />
                <AntSwitch checked={values.accent_color === "#000000de"} onChange={handleAccentColorChange} />
                <Box
                  sx={{
                    width: 58,
                    height: 30,
                    backgroundColor: "#000000de",
                    borderRadius: "3px",
                    border: "1px rgb(73, 169, 222) solid",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={1}>
            <Box sx={{ fontSize: 12, color: "#333" }}>Preview</Box>
            <Box
              sx={{
                width: 160,
                minWidth: 160,
                maxWidth: 160,
                p: 2,
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: values.primary_color,
                fontSize: 24,
                color: values.accent_color,
                border: "1px solid #000",
                borderRadius: "5px",
              }}
            >
              Stella 2
            </Box>
          </Stack>
        </Box>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Starter Card</Box>

        <Stack spacing={4}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
            <Stack spacing={1} sx={{ minWidth: 160 }}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Turn on/off the start card</Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Off</Typography>
                <AntSwitch checked={values.starter_display} onChange={handleStarterDisplayChange} />
                <Typography>On</Typography>
              </Stack>
            </Stack>

            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Delay duration of the start card (millisecond)</Box>
              <TextField
                size="small"
                placeholder="millisecond"
                type="number"
                value={values.starter_delay}
                onChange={(e) => {
                  setValues({ ...values, starter_delay: e.target.value });
                }}
                onBlur={handleStarterDelayChange}
                sx={{ maxWidth: 160 }}
              />
            </Stack>
          </Box>

          <Stack spacing={1}>
            <TextField
              fullWidth
              value={values.starter_text}
              onChange={(e) => {
                if (e.target.value && e.target.value.length <= 255) {
                  setValues({ ...values, starter_text: e.target.value });
                }
              }}
              onBlur={handleStarterTextChange}
              label={`Text of the start card (${values.starter_text.length}/255)`}
            />
          </Stack>

          <UploadStarterHero />
        </Stack>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>AI</Box>

        <Stack spacing={4}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>AI name</Box>
              <TextField
                size="small"
                placeholder="AI name"
                value={values.ai_name}
                onChange={(e) => {
                  setValues({ ...values, ai_name: e.target.value });
                }}
                onBlur={handleAiNameChange}
                sx={{ maxWidth: 160 }}
              />
            </Stack>

            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>AI subtitle</Box>
              <TextField
                size="small"
                placeholder="AI subtitle"
                value={values.ai_subtitle}
                onChange={(e) => {
                  setValues({ ...values, ai_subtitle: e.target.value });
                }}
                onBlur={handleAiSubtitleChange}
                sx={{ maxWidth: 160 }}
              />
            </Stack>
          </Box>

          <UploadAiAvatar />
        </Stack>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Embed Chat Widget</Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <RadioInput
            legend="Select the position of embed chat widget"
            row
            options={positionOptions}
            value={values.position}
            onChange={handlePositionChange}
          />

          <EmbedScript
            description="Place this code in the location you want the widget to appear"
            script={widgetScript}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default EmbedChatWidget;
