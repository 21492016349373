export const GAP_TIME_LIST = [
  {
    value: 0,
    label: "No Buffer Time",
  },
  {
    value: 30,
    label: "30 mins",
  },
  {
    value: 60,
    label: "1 hour",
  },
  {
    value: 90,
    label: "90 mins",
  },
];

export const TIMEZONE_LIST = [
  {
    value: "custom_homeowner_timezone",
    label: "Use Homeowner's Time Zone",
  },
  {
    value: "US/Pacific",
    label: "Pacific Standard Time",
  },
  {
    value: "US/Mountain",
    label: "Mountain Standard Time",
  },
  {
    value: "US/Central",
    label: "Central Standard Time",
  },
  {
    value: "US/Eastern",
    label: "Eastern Standard Time",
  },
  {
    value: "Pacific/Honolulu",
    label: "Hawaii-Aleutian Time Zone",
  },
  {
    value: "US/Alaska",
    label: "Alaska Time Zone",
  },
];

export const SINGLE_CHOICE_QN = "single-choice";

export const OPEN_ENDED_QN = "open-ended";
