import React from "react";
import { Box, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const PhoneEditForm = ({ field, label = null }) => {
  const setupWizard = useQuickSetupWizard();
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setError(setupWizard.errors[field]);
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handlePhoneChange = async (e) => {
    if (e.target.value === "") {
      setError("required");
      setupWizard.update(field, "required");
    } else {
      setError("");
      setupWizard.update(field, e.target.value);
    }
  };

  return (
    <Box>
      <Box sx={{ color: "error.main" }}>{error === "required" && "Required"}</Box>
      <TextField
        label={label ?? ""}
        defaultValue={setupWizard.client[field] || ""}
        inputProps={{
          onKeyUp: handlePhoneChange,
        }}
        sx={{ minWidth: 400 }}
      />
    </Box>
  );
};

export default PhoneEditForm;
