import React from "react";
import { useRecoilState } from "recoil";
import { Box, Paper, Grid, Button, Dialog } from "@mui/material";
import { authAtom } from "store/auth";

const EarnRewardsPage = () => {
  const [authState] = useRecoilState(authAtom);
  const [reviewLink, setReviewLink] = React.useState("");

  const handleRefer = () => {
    const emailCC = "jordy@demand-iq.com,marcr@demand-iq.com";
    const emailSubject = `Referral (from ${authState.user.first_name} ${authState.user.last_name})`;
    const emailBody =
      "I'm excited to share a referral to Demand IQ: an online lead generation software system that helps us get more leads without purchasing them.\nI love the software, and think you will too!\nJust reply to this email and they'll take care of you.\nWith my referral, you'll get your $200 in credits + free onboarding - a $700 discount :)\nI've cc'ed their teammate Marc to help.";
    window.location.href = `mailto:?cc=${emailCC}&subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`;
  };

  const handleGoogleReview = () => {
    setReviewLink("https://g.page/Demand-IQ/review?rc");
  };

  const handleFacebookReview = () => {
    setReviewLink("https://www.facebook.com/demandiqads/reviews");
  };

  const handleTrustpilotReview = () => {
    setReviewLink("https://www.trustpilot.com/evaluate/demand-iq.com");
  };

  return (
    <Box p={4}>
      <Box sx={{ fontSize: 24, color: "primary.main" }}>Earn Credits</Box>

      <Box my={4} sx={{ textAlign: "center" }}>
        <Box component="img" src="/images/icon-like.png" alt="Heart" sx={{ width: 120 }} />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box component="img" src="/images/icon-new.png" alt="Heart" sx={{ width: 55 }} />
        <Box ml={2} sx={{ fontSize: 28, fontWeight: 500 }}>
          Earn Free Credits
        </Box>
      </Box>

      <Box my={4} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ textAlign: "center", maxWidth: 420, fontSize: 18 }}>
          Earn as much as you want. Unused bonus credits roll over month to month and never expire.
        </Box>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Box p={4}>
              <Box sx={{ fontSize: 24 }}>Refer your friends</Box>
              <Box sx={{ fontSize: 20, color: "success.light" }}>+$200 credit value</Box>
              <Box mt={4}>
                Give $200 credits and get $200 credits for each new account that signs up with your link. Credits are
                applied 7 days after your referrals start their subscription.
              </Box>
              <Box mt={4} sx={{ textAlign: "right" }}>
                <Button variant="contained" onClick={handleRefer}>
                  REFER NOW
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <Box p={4}>
              <Box sx={{ fontSize: 24 }}>Google Review</Box>
              <Box sx={{ fontSize: 20, color: "success.light" }}>+$100 credit value</Box>
              <Box mt={4}>
                Get $100 worth of credits by writing a review of Demand IQ on Google. Once it is verified by a team
                member we will add the credits to your account.
              </Box>
              <Box mt={4}>
                <Button variant="contained" onClick={handleGoogleReview}>
                  CLAIM REWARD
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <Box p={4}>
              <Box sx={{ fontSize: 24 }}>Facebook Review</Box>
              <Box sx={{ fontSize: 20, color: "success.light" }}>+$100 credit value</Box>
              <Box mt={4}>
                Get $100 worth of credits by writing a review of Demand IQ on Facebook. Once it is verified by a team
                member we will add the credits to your account.
              </Box>
              <Box mt={4}>
                <Button variant="contained" onClick={handleFacebookReview}>
                  CLAIM REWARD
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <Box p={4}>
              <Box sx={{ fontSize: 24 }}>Trustpilot Review</Box>
              <Box sx={{ fontSize: 20, color: "success.light" }}>+$100 credit value</Box>
              <Box mt={4}>
                Get $100 worth of credits by writing a review of Demand IQ on Trustpilot. Once it is verified by a team
                member we will add the credits to your account.
              </Box>
              <Box mt={4}>
                <Button variant="contained" onClick={handleTrustpilotReview}>
                  CLAIM REWARD
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={Boolean(reviewLink)}
        onClose={() => setReviewLink("")}
        maxWidth="md"
        PaperProps={{ sx: { width: "100%" } }}
      >
        <Box p={4}>
          <Box sx={{ fontSize: 28 }}>How it works</Box>
          <Box my={8} sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Box sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box component="img" src="/images/icon-review.png" alt="Share Your Experience" sx={{ height: 90 }} />
              <Box mt={2} sx={{ textAlign: "center" }}>
                Share Your Experience
              </Box>
            </Box>

            <Box sx={{ width: "5%", height: "2px", backgroundColor: "#333", position: "relative", top: 45 }}></Box>

            <Box sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box
                component="img"
                src="/images/icon-screenshot.png"
                alt="Email a Screenshot to Your Account Manager"
                sx={{ height: 90 }}
              />
              <Box mt={2} sx={{ textAlign: "center" }}>
                Email a Screenshot to Your Account Manager
              </Box>
            </Box>

            <Box sx={{ width: "5%", height: "2px", backgroundColor: "#333", position: "relative", top: 45 }}></Box>

            <Box sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box component="img" src="/images/icon-salary.png" alt="Earn Rewards" sx={{ height: 90 }} />
              <Box mt={2} sx={{ textAlign: "center" }}>
                Earn Rewards
              </Box>
            </Box>
          </Box>

          <Box mt={4} sx={{ textAlign: "center" }}>
            <Button component="a" href={reviewLink} target="_blank" variant="contained">
              LET'S DO THIS
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default EarnRewardsPage;
