import axios from "axios";

const baseURL = "/api/v2";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = baseURL;

const apiClient = () => {
  let headers = {};
  const token = localStorage.getItem("jwt_access_token") || "";
  const company = localStorage.getItem("company") || "";
  if (token) {
    headers["Authorization"] = "Bearer " + token;
    headers["diq-com"] = company;
  }

  const instance = axios.create({ baseURL, headers });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.config.url !== "/auth/token/refresh/" &&
        error.response.status !== "/auth/token/"
      ) {
        try {
          const res = await axios.post("/auth/token/refresh/", { refresh: localStorage.getItem("jwt_refresh_token") });
          window.localStorage.setItem("jwt_access_token", res.data.access);
          error.config.__isRetryRequest = true;
          error.config.headers.Authorization = "Bearer " + res.data.access;
          instance.defaults.headers.common["Authorization"] = "Bearer " + res.data.access;
          return instance(error.config);
        } catch (e) {
          if (window.location.pathname !== "/login") window.location.href = "/login";
        }
      }
      return error.response;
    }
  );
  return instance;
};

export default apiClient;
