import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import uuid from "uuid";
import Switch from "shared/Switch";
import Battery from "./Battery";
import Charger from "./Charger";
import AddBatteryBox from "./AddBatteryBox";
import EditSellsForm from "./EditSellsForm";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import {
  apiGetBatteries,
  apiGetChargers,
  apiCreateBattery,
  apiUpdateBattery,
  apiDeleteBattery,
  apiCreateCharger,
  apiUpdateCharger,
  apiDeleteCharger,
} from "store/api";

const BATTERY = "battery";
const CHARGER = "charger";
const LABELS = {
  [BATTERY]: "Use Battery",
  [CHARGER]: "Use Charger",
};

const SelectUpsell = () => {
  const setupWizard = useQuickSetupWizard();

  const [toggle, setToggle] = React.useState([]);
  const [sells, setSells] = React.useState([]);
  const [form, setForm] = React.useState(null);

  const types = [BATTERY, CHARGER];

  React.useEffect(() => {
    types.forEach((type) => {
      if (toggle?.[type] && !sells[type]?.length) handleAdd(type);
    });
  }, [toggle]);

  React.useEffect(async () => {
    let mounted = true;

    const init = async () => {
      const res = await Promise.all([
        apiGetBatteries({ solar_company: setupWizard.client.id }),
        apiGetChargers({ solar_company: setupWizard.client.id }),
      ]);
      if (mounted) {
        setSells({
          [BATTERY]: res[0].data || [],
          [CHARGER]: res[1].data || [],
        });
        setToggle({
          [BATTERY]: setupWizard.client.is_battery_on || false,
          [CHARGER]: setupWizard.client.is_charger_on || false,
        });
      }
    };
    init();

    return () => {
      mounted = false;
    };
  }, []);

  const handleToggle = (type) => (e) => {
    setToggle({ ...toggle, [type]: e.target.checked });
    switch (type) {
      case BATTERY:
        setupWizard.update("is_battery_on", e.target.checked);
        break;
      case CHARGER:
        setupWizard.update("is_charger_on", e.target.checked);
        break;
      default:
    }
  };

  const handleAdd = (type) => {
    setForm({ id: uuid.v4(), type, action: "add" });
  };

  const handleSave = async (sell) => {
    const type = form.type;

    let apiCreate, apiUpdate;
    switch (type) {
      case BATTERY:
        apiCreate = apiCreateBattery;
        apiUpdate = apiUpdateBattery;
        break;
      case CHARGER:
        apiCreate = apiCreateCharger;
        apiUpdate = apiUpdateCharger;
        break;
      default:
        return;
    }

    if (form.action === "add") {
      sell.id = undefined;
      const res = await apiCreate(
        { solar_company: setupWizard.client.id },
        { ...sell, solar_company: setupWizard.client.id }
      );
      if (res.status === 201) {
        if (!sells[type]) sells[type] = [];
        setSells({
          ...sells,
          [type]: [...sells[type], { ...res.data }],
        });
        setForm(null);
      } else {
        handleClose();
      }
    } else {
      const res = await apiUpdate(sell.id)(
        { solar_company: setupWizard.client.id },
        { ...sell, solar_company: setupWizard.client.id }
      );
      if (res.status === 200) {
        setSells({
          ...sells,
          [type]: sells[type].map((item) => (item.id === sell.id ? res.data : item)),
        });
        setForm(null);
      } else {
        handleClose();
      }
    }
  };

  const handleClose = () => {
    const type = form.type;
    if (!sells[type] || !sells[type].length) {
      setToggle({ ...toggle, [type]: false });

      switch (type) {
        case BATTERY:
          setupWizard.update("is_battery_on", false);
          break;
        case CHARGER:
          setupWizard.update("is_charger_on", false);
          break;
        default:
      }
    }
    setForm(null);
  };

  const handleEdit = (sell, type) => {
    setForm({ ...sell, type, action: "edit" });
  };

  const handleDelete = async (sell, type) => {
    let apiDelete;
    switch (type) {
      case BATTERY:
        apiDelete = apiDeleteBattery;
        break;
      case CHARGER:
        apiDelete = apiDeleteCharger;
        break;
      default:
        return;
    }

    const res = await apiDelete(sell.id);
    if (res.status === 204)
      setSells({
        ...sells,
        [type]: sells[type].filter((item) => item.id !== sell.id),
      });
  };

  return (
    <Box>
      <Box mb={2} sx={{ fontSize: 12 }}>
        Based on {setupWizard.client.product_apr || 2}% APR financing for {setupWizard.client.product_term || 10} years.
      </Box>

      <Stack spacing={2}>
        {types.map((type, index) => (
          <Box key={index}>
            <Switch label={LABELS[type]} checked={toggle?.[type] || false} onChange={handleToggle(type)} />

            {toggle?.[type] && !!sells?.[type]?.length && (
              <Box my={2} sx={{ display: "flex", flexWrap: "wrap" }}>
                {sells[type].map((sell, index) =>
                  type === BATTERY ? (
                    <Battery
                      key={index}
                      {...sell}
                      deleteOn={sells[type].length > 1}
                      onEdit={() => handleEdit(sell, type)}
                      onDelete={() => handleDelete(sell, type)}
                    />
                  ) : (
                    <Charger key={index} {...sell} onEdit={() => handleEdit(sell, type)} />
                  )
                )}
                {type === BATTERY && sells[type].length < 3 && <AddBatteryBox onAdd={() => handleAdd(type)} />}
              </Box>
            )}
          </Box>
        ))}
      </Stack>
      <EditSellsForm key={form?.id} {...form} onSave={handleSave} onClose={handleClose} />
    </Box>
  );
};

export default SelectUpsell;
