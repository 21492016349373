import React from "react";
import { Box, Stack, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import DesktopPreview from "shared/Preview/DesktopPreview";

const ChangeWelcome = () => {
  const setupWizard = useQuickSetupWizard();

  const [welcome, setWelcome] = React.useState({
    welcome_statement: "",
  });

  const [errors, setErrors] = React.useState({
    welcome_statement: "",
  });

  React.useEffect(() => {
    setWelcome({
      welcome_statement:
        setupWizard.client.welcome_statement ||
        "Hey! I'm {{ai_name}}. I'll get you a custom solar estimate in seconds using satellites!",
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setErrors({
      welcome_statement: setupWizard.errors.welcome_statement,
    });
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleChange = (e, key, len) => {
    if (e.target.value.length <= len) {
      if (e.target.value === "") {
        setErrors((state) => ({
          ...state,
          [key]: "required",
        }));
      } else {
        setErrors((state) => ({
          ...state,
          [key]: "",
        }));
      }
      setWelcome((state) => ({ ...state, [key]: e.target.value }));
      setupWizard.update(key, e.target.value);
    }
  };

  return (
    <Box>
      <Stack mt={2} spacing={2}>
        {[{ key: "welcome_statement", len: 128 }].map(({ key, len }) => (
          <>
            {errors[key] && (
              <Box sx={{ color: "error.main" }}>
                {errors[key] === "required" && "Required"}
                {errors[key] !== "required" && errors[key]}
              </Box>
            )}
            <TextField
              fullWidth
              value={welcome[key]}
              onChange={(e) => handleChange(e, key, len)}
              label={`${welcome[key].length}/${len}`}
            />
          </>
        ))}
      </Stack>

      <Box mt={4}>
        <DesktopPreview page="start" isQuickSetup />
      </Box>
    </Box>
  );
};

export default ChangeWelcome;
