import React from "react";
import { Box, Stack, TextField } from "@mui/material";
import useSpanishSetupWizard from "hooks/useSpanishSetupWizard";
import DesktopPreview from "shared/Preview/DesktopPreview";

const ChangeThanksStatement = () => {
  const spanishWizard = useSpanishSetupWizard();
  const [value, setValue] = React.useState({
    spanish_thanks_caption: "",
    spanish_thanks_statement: "",
  });
  const [error, setError] = React.useState({
    spanish_thanks_caption: "",
    spanish_thanks_statement: "",
  });

  React.useEffect(() => {
    setValue({
      spanish_thanks_caption: spanishWizard.client.spanish_thanks_caption || "¡Se está preparando tu cotización!",
      spanish_thanks_statement: spanishWizard.client.spanish_thanks_statement || "",
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setError({
      spanish_thanks_caption: spanishWizard.errors.spanish_thanks_caption,
      spanish_thanks_statement: spanishWizard.errors.spanish_thanks_statement,
    });
    // eslint-disable-next-line
  }, [spanishWizard.errors]);

  const handleChange = (e, key, len) => {
    if (e.target.value.length <= len) {
      if (e.target.value === "") {
        setError((state) => ({
          ...state,
          [key]: "required",
        }));
      } else {
        setError((state) => ({
          ...state,
          [key]: "",
        }));
      }
      setValue((state) => ({ ...state, [key]: e.target.value }));
      spanishWizard.update(key, e.target.value);
    }
  };

  return (
    <Box>
      <Stack mt={2} spacing={2}>
        {[
          { key: "spanish_thanks_caption", label: "Spanish-caption", len: 48 },
          { key: "spanish_thanks_statement", label: "Spanish-statement", len: 255 },
        ].map(({ key, label, len }) => (
          <>
            {error[key] && (
              <Box sx={{ color: "error.main" }}>
                {error[key] === "required" && "Required"}
                {error[key] !== "required" && error[key]}
              </Box>
            )}
            <TextField
              fullWidth
              value={value[key]}
              onChange={(e) => handleChange(e, key, len)}
              label={`${label} (${value[key].length}/${len} chars max)`}
            />
          </>
        ))}
      </Stack>

      <Box mt={4}>
        <DesktopPreview page="thankyou" isSpanishSetup lang="es" />
      </Box>
    </Box>
  );
};

export default ChangeThanksStatement;
