import React from "react";
import { Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import ColorPicker from "shared/wizard/ColorPicker";

const keys = [
  "color_scheme_light",
  "color_scheme_dark",
  "color_scheme_neutral",
  "color_scheme_font",
  "color_scheme_label",
  "color_scheme_button",
  "color_scheme_button_hover",
  "color_scheme_button_text",
  "color_scheme_button_hover_text",
];

const keyLabels = {
  color_scheme_light: "Light",
  color_scheme_dark: "Dark",
  color_scheme_neutral: "Neutral",
  color_scheme_font: "Font",
  color_scheme_label: "Label",
  color_scheme_button: "Button",
  color_scheme_button_hover: "Button Hover",
  color_scheme_button_text: "Button Text",
  color_scheme_button_hover_text: "Button Hover Text",
};

const SelectColorScheme = () => {
  const setupWizard = useQuickSetupWizard();

  const [values, setValues] = React.useState({
    color_scheme_light: "#fff",
    color_scheme_dark: "#fff",
    color_scheme_neutral: "#fff",
    color_scheme_font: "#fff",
    color_scheme_label: "#fff",
    color_scheme_button: "#fff",
    color_scheme_button_hover: "#fff",
    color_scheme_button_text: "#fff",
    color_scheme_button_hover_text: "#fff",
  });

  React.useEffect(() => {
    setValues({
      color_scheme_light: setupWizard.client.color_scheme_light,
      color_scheme_dark: setupWizard.client.color_scheme_dark,
      color_scheme_neutral: setupWizard.client.color_scheme_neutral,
      color_scheme_font: setupWizard.client.color_scheme_font,
      color_scheme_label: setupWizard.client.color_scheme_label,
      color_scheme_button: setupWizard.client.color_scheme_button,
      color_scheme_button_hover: setupWizard.client.color_scheme_button_hover,
      color_scheme_button_text: setupWizard.client.color_scheme_button_text,
      color_scheme_button_hover_text: setupWizard.client.color_scheme_button_hover_text,
    });
    // eslint-disable-next-line
  }, []);

  const handleColorChange = (key) => (color) => {
    setValues({
      ...values,
      [key]: color,
    });
    setupWizard.update(key, color);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", gap: 2, maxWidth: 600 }}>
      {keys.map((key) => (
        <Box key={key}>
          <Box>{keyLabels[key]}</Box>

          <ColorPicker value={values[key]} onChange={handleColorChange(key)} width={160} />
        </Box>
      ))}
    </Box>
  );
};

export default SelectColorScheme;
