import { Stack, MenuItem } from "@mui/material";
import WizardText from "../shared/WizardText";
import WizardDate from "../shared/WizardDate";

const FeeForm = () => {
  return (
    <Stack spacing={2}>
      <WizardText select fullWidth type="number" name="level" label="Subscription Plan">
        <MenuItem value="starter">Starter</MenuItem>
        <MenuItem value="premier">Premier</MenuItem>
        <MenuItem value="pro">Pro</MenuItem>
        <MenuItem value="enterprise">Enterprise</MenuItem>
      </WizardText>
      <WizardDate name="date_contract_signed" label="Date contract signed" />
      <WizardText fullWidth type="number" name="minimum_monthly_fee" label="Minimum monthly fee" />
      <WizardText fullWidth type="number" name="cost_per_lead" label="Cost per Lead" />
      <WizardText fullWidth type="number" name="cost_per_appointment" label="Cost per Appointment" />
      <WizardText fullWidth type="number" name="cost_per_appointment_with_bill" label="Cost per Utility Bill Pull" />
      <WizardText fullWidth type="number" name="cost_per_estimate_viewer" label="Cost per Estimate Viewer" />
    </Stack>
  );
};

export default FeeForm;
