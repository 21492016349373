import React from "react";
import { Box, TextField } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const HeadlineText = () => {
  const hConfigurator = useConfigurator();
  const [value, setValue] = React.useState("");
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setValue(hConfigurator.client.banner_button_text);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    if (e.target.value.length <= 24) {
      setValue(e.target.value);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    await hConfigurator.saveSingle("banner_button_text", value);
    setSaving(false);
  };

  return (
    <Box>
      <TextField
        fullWidth
        label={`Button Text (${value.length}/24 chars max)`}
        value={value}
        onChange={handleChange}
        onBlur={handleSave}
        disabled={saving}
      />
    </Box>
  );
};

export default HeadlineText;
