import React from "react";
import { Box, Divider, Stack, FormControlLabel, Checkbox } from "@mui/material";
import Filter from "./components/filter/Filter";
import LeadStatus from "./components/lead-status/LeadStatus";
import EngagementStats from "./components/engagement-stats/EngagementStats";
import TrafficHeatMap from "./components/traffic-heat-map/TrafficHeatMap";
import EmailNotification from "./components/email-notification/EmailNotification";
import ExportLead from "./components/export-lead/ExportLead";
import useReporting from "hooks/useReporting";
import { useRecoilValue } from "recoil";
import { clientAtom } from "store/client";

const MainReport = () => {
  const clientState = useRecoilValue(clientAtom);
  const isStella2Enabled = clientState.current?.enable_chat;

  const hReporting = useReporting();

  const handleStella2Change = (e) => {
    hReporting.main.setFilter({
      stella2only: e.target.checked,
    });
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Filter />
        <Stack p={4} direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
          <EmailNotification />
          <ExportLead />
        </Stack>
      </Stack>

      {isStella2Enabled && (
        <Box sx={{ px: 4, mt: -2, pb: 4 }}>
          <FormControlLabel
            control={<Checkbox checked={hReporting.main.filter.stella2only} onChange={handleStella2Change} />}
            label="Stella 2 Only"
          />
        </Box>
      )}

      <Divider />

      <Stack p={4} spacing={4}>
        <LeadStatus />
        <EngagementStats />
        <TrafficHeatMap />
      </Stack>
    </Box>
  );
};

export default MainReport;
