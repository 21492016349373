import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Div100vh from "react-div-100vh";
import { Box, Stack, Button, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { apiResetPasswordConfirm } from "store/api";
import FormText from "shared/FormText";
import "./style.scss";

const ResetPasswordPage = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const hAlert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm();

  const onSubmit = async (d) => {
    setLoading(true);
    const res = await apiResetPasswordConfirm({
      ...d,
      uid,
      token,
    });
    if (res.status === 200) {
      hAlert.show("Password has been reset with the new password.");
      navigate("/login");
    } else {
      for (let p in res.data) {
        if (["uid", "token"].includes(p)) {
          hAlert.show("Password reset link is incorrect.", false);
        } else {
          hAlert.show(res.data[p][0], false);
        }
      }
      setLoading(false);
    }
  };

  return (
    <Div100vh className="reset-password-page">
      <Box className="section-left">
        <Stack className="promotion" spacing={4}>
          <Box sx={{ fontSize: 24, fontWeight: 900, textTransform: "uppercase" }}>Enterprise</Box>
          <Box sx={{ fontSize: 28 }}>The most advanced customer acquisition platform for clean energy.</Box>
          <Box>
            <Button color="inherit" variant="contained" sx={{ backgroundColor: "#fff", color: "primary.main" }}>
              Learn more
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box className="section-main">
        <Box className="section-main-container">
          <Paper sx={{ width: 480 }}>
            <Box p={4}>
              <Box sx={{ textAlign: "center" }}>
                <img className="stella-logo" src="/images/stella.png" alt="" />
              </Box>
              <Box mt={2} mb={4} sx={{ fontSize: 24, textAlign: "center" }}>
                Reset Password
              </Box>
              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2}>
                    <FormText
                      disabled={loading}
                      control={control}
                      type="password"
                      name="new_password1"
                      label="New password *"
                      required
                    />
                    <FormText
                      disabled={loading}
                      control={control}
                      type="password"
                      name="new_password2"
                      label="Confirm Password *"
                      required
                    />
                    <LoadingButton loading={loading} fullWidth type="submit" variant="contained">
                      Reset
                    </LoadingButton>
                  </Stack>
                </form>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Div100vh>
  );
};

export default ResetPasswordPage;
